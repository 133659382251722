import React, { useState } from "react";
import PropTypes from "prop-types";

import HelpTrigger from "@/components/HelpTrigger";
import { Alert as AlertType } from "@/components/proptypes";

import Form from "antd/lib/form";
import Button from "antd/lib/button";

import Title from "./components/Title";
import Criteria from "./components/Criteria";
import NotificationTemplate from "./components/NotificationTemplate";
import Rearm from "./components/Rearm";
import Query from "./components/Query";
import HorizontalFormItem from "./components/HorizontalFormItem";
import notification from "@/services/notification";

export default function AlertNew(props) {
  const { alert, queryResult, pendingRearm, onNotificationTemplateChange } = props
  const { onQuerySelected, onNameChange, onRearmChange, onCriteriaChange } = props
  const { query, name, options } = alert

  const [saving, setSaving] = useState(false)
  const [invalid, setInvalid] = useState('')

  function isInvalid(bool) {
    setInvalid(bool)
  }

  const save = () => {
    if (!invalid) {
      setSaving(true);
      props.save().catch(() => {
        setSaving(false)
      })
    } else {
      notification.error(invalid)
    }

  }

  return (
    <>
      <Title alert={alert} name={name} onChange={onNameChange} editMode />
      <div className="bg-white tiled p-20">
        <div className="d-flex">
          <Form className="flex-fill">
            <div className="m-b-30">
              请选择想要监控的查询（不适用于带有参数的查询）
            </div>
            <HorizontalFormItem label="查询">
              <Query query={query} queryResult={queryResult} onChange={onQuerySelected} editMode />
            </HorizontalFormItem>
            {queryResult && options && (
              <>
                <HorizontalFormItem label="触发条件" className="alert-criteria">
                  <Criteria
                    columnNames={queryResult.getColumnNames()}
                    resultValues={queryResult.getData()}
                    alertOptions={options}
                    onChange={onCriteriaChange}
                    editMode
                    isInvalid={isInvalid}
                  />
                </HorizontalFormItem>
                <HorizontalFormItem label="发送通知">
                  <Rearm value={pendingRearm || 0} onChange={onRearmChange} editMode />
                </HorizontalFormItem>
                <HorizontalFormItem label="模板">
                  <NotificationTemplate
                    alert={alert}
                    query={query}
                    columnNames={queryResult.getColumnNames()}
                    resultValues={queryResult.getData()}
                    subject={options.custom_subject}
                    setSubject={subject => onNotificationTemplateChange({ custom_subject: subject })}
                    body={options.custom_body}
                    setBody={body => onNotificationTemplateChange({ custom_body: body })}
                  />
                </HorizontalFormItem>
              </>
            )}
            <HorizontalFormItem>
              <Button type="primary" onClick={save} disabled={!query} className="btn-create-alert">
                {saving && (
                  <span role="status" aria-live="polite" aria-relevant="additions removals">
                    <i className="fa fa-spinner fa-pulse m-r-5" aria-hidden="true" />
                    <span className="sr-only">保存中...</span>
                  </span>
                )}
                创建提醒
              </Button>
            </HorizontalFormItem>
          </Form>
          <HelpTrigger className="f-13" type="ALERT_SETUP">
            设置说明 <i className="fa fa-question-circle" aria-hidden="true" />
            <span className="sr-only">(帮助)</span>
          </HelpTrigger>
        </div>
      </div>
    </>
  );
}

// export default class AlertNew extends React.Component {
//   constructor (props) {
//     super(props)
//     this.state = {
//       saving: false,
//       invalid: true
//     }
//   }

//   isInvalid(bool) {
//     this.setState( { invalid: bool })
//     console.log()
//   }

//   save = () => {
//     this.setState({ saving: true });
//     this.props.save().catch(() => {
//       this.setState({ saving: false });
//     });
//   };

//   render() {
//     const { alert, queryResult, pendingRearm, onNotificationTemplateChange } = this.props;
//     const { onQuerySelected, onNameChange, onRearmChange, onCriteriaChange } = this.props;
//     const { query, name, options } = alert;
//     const { saving } = this.state;

//     return (
//       <>
//         <Title alert={alert} name={name} onChange={onNameChange} editMode />
//         <div className="bg-white tiled p-20">
//           <div className="d-flex">
//             <Form className="flex-fill">
//               <div className="m-b-30">
//                 请选择想要监控的查询（不适用于带有参数的查询）
//               </div>
//               <HorizontalFormItem label="查询">
//                 <Query query={query} queryResult={queryResult} onChange={onQuerySelected} editMode />
//               </HorizontalFormItem>
//               {queryResult && options && (
//                 <>
//                   <HorizontalFormItem label="触发条件" className="alert-criteria">
//                     <Criteria
//                       columnNames={queryResult.getColumnNames()}
//                       resultValues={queryResult.getData()}
//                       alertOptions={options}
//                       onChange={onCriteriaChange}
//                       editMode
//                       invalid={invalid}
//                     />
//                   </HorizontalFormItem>
//                   <HorizontalFormItem label="发送通知">
//                     <Rearm value={pendingRearm || 0} onChange={onRearmChange} editMode />
//                   </HorizontalFormItem>
//                   <HorizontalFormItem label="模板">
//                     <NotificationTemplate
//                       alert={alert}
//                       query={query}
//                       columnNames={queryResult.getColumnNames()}
//                       resultValues={queryResult.getData()}
//                       subject={options.custom_subject}
//                       setSubject={subject => onNotificationTemplateChange({ custom_subject: subject })}
//                       body={options.custom_body}
//                       setBody={body => onNotificationTemplateChange({ custom_body: body })}
//                     />
//                   </HorizontalFormItem>
//                 </>
//               )}
//               <HorizontalFormItem>
//                 <Button type="primary" onClick={this.save} disabled={!query} className="btn-create-alert">
//                   {saving && (
//                     <span role="status" aria-live="polite" aria-relevant="additions removals">
//                       <i className="fa fa-spinner fa-pulse m-r-5" aria-hidden="true" />
//                       <span className="sr-only">保存中...</span>
//                     </span>
//                   )}
//                   创建提醒
//                 </Button>
//               </HorizontalFormItem>
//             </Form>
//             <HelpTrigger className="f-13" type="ALERT_SETUP">
//               设置说明 <i className="fa fa-question-circle" aria-hidden="true" />
//               <span className="sr-only">(帮助)</span>
//             </HelpTrigger>
//           </div>
//         </div>
//       </>
//     );
//   }
// }

AlertNew.propTypes = {
  alert: AlertType.isRequired,
  queryResult: PropTypes.object, // eslint-disable-line react/forbid-prop-types,
  pendingRearm: PropTypes.number,
  onQuerySelected: PropTypes.func.isRequired,
  save: PropTypes.func.isRequired,
  onNameChange: PropTypes.func.isRequired,
  onRearmChange: PropTypes.func.isRequired,
  onCriteriaChange: PropTypes.func.isRequired,
  onNotificationTemplateChange: PropTypes.func.isRequired,
};

AlertNew.defaultProps = {
  queryResult: null,
  pendingRearm: null,
};
