import debug from "debug";
import {includes, extend} from "lodash";
import location from "@/services/location";
import {axios} from "@/services/axios";
import {notifySessionRestored} from "@/services/restoreSession";
import login from "axios";

export const currentUser = {
  _isAdmin: undefined,

  canEdit(object) {
    const userId = object.user_id || (object.user && object.user.id);
    // return this.isAdmin || (userId && userId === this.id);
    return this.isAdmin;
  },

  canCreate() {
    return (
      this.hasPermission("create_query") || this.hasPermission("create_dashboard") || this.hasPermission("list_alerts")
    );
  },

  hasPermission(permission) {
    console.log(this.permissions);
    if (permission === "admin" && this._isAdmin !== undefined) {
      return this._isAdmin;
    }
    // console.log("this.permissions",this.permissions);
    return includes(this.permissions, permission);
  },

  get isAdmin() {
    return this.hasPermission("admin");
  },

  set isAdmin(isAdmin) {
    this._isAdmin = isAdmin;
  },

  get id() {
    return console.log('访问了id');
  },
};

// export const clientConfig = {}
export const clientConfig = {
  queryRefreshIntervals: [
    60,
    300,
    600, // 1, 5 ,10 mins
    3600,
    36000,
    82800, // 1, 10, 23 hours
    86400,
    172800,
    518400, // 1, 2, 6 days
    604800,
    1209600,
    2419200, // 1, 2, 4 weeks
  ],
  mailSettingsMissing: false
};
export const messages = [];

const logger = debug("redash:auth");
const session = {loaded: false};

const AuthUrls = {
  Login: "login",
};
let token = "";
let userid = "";

export function updateClientConfig(newClientConfig) {
  extend(clientConfig, newClientConfig);
}

function updateSession(sessionData) {
  extend(session, sessionData, {loaded: true});
  extend(currentUser, session.user);
  extend(clientConfig, null);
  // extend(clientConfig, []);
  // extend(messages, session.messages);
}

export const Auth = {
  isAuthenticated() {
    return session.loaded;
  },
  setApiKey(apiKey) {
    Auth.apiKey = apiKey;
  },
  getApiKey() {
    return Auth.apiKey;
  },
  // getLoginUrl() {
  //   return AuthUrls.Login;
  // },
  // setLoginUrl(loginUrl) {
  //   AuthUrls.Login = loginUrl;
  // },
  // login() {
  //   // 解码url上的信息
  //   const next = encodeURI(location.url);
  //   logger("Calling login with next = %s", next);
  //   window.location.href = `${AuthUrls.Login}?next=${next}`;
  // },
  //  请求菜单接口，获取相应的token值进行请求
  loadSession() {
    token = 'ssss';
    // Auth.setApiKey(token);
    if (session.loaded) {
      return Promise.resolve(session);
    }

    const userId = localStorage.getItem('userId')
    const base = window.location.origin

    // return axios.get("api/menu").then(data => {
      
    // return axios.get(`uuas/menu/route${{userId, part: 'bi'}}`).then(data => {
    // return axios.get("api/menu").then(data => {
    return axios.get(`${base}/api/uuas/menu/router?userId=${userId}&part=bi`).then(res => {
      console.log();
      const data = res.data
      console.log(data);
      //开始处理配置的url
      let ss = [];
      // ss.push('admin');
      // ss.push('super_admin');
      if (data.length > 0) {
        data.forEach(e => {
          if (e.path.indexOf('/') !== -1) {
            ss.push(e.path.slice(1, e.path.length));
          }

        });
      }
      let user = {
        "permissions": ss,
      }
      let ff = {
        "user": user
      }
      updateSession(ff);
      return session;
    });
  },
//   getUrlVal() {

//     // 获取参数
//      var url = window.location.search; //获取url中"?"符后的字串
//     // var url="?token=ssssssss&userid=5555";
//     var theRequest = new Object();
//     if (url.indexOf("?") != -1) {
//       var str = url.substr(1);
//       var strs = str.split("&");
//       for(var i = 0; i < strs.length; i ++) {
//         theRequest[strs[i].split("=")[0]]=unescape(strs[i].split("=")[1]);
//       }
//     }
//     token = theRequest.token;
//     console.log("token",decodeURIComponent(token));
//     userid=theRequest.userid;
//     console.log("userid",decodeURIComponent(userid));

// },

  loadConfig() {
    logger("Loading config");
    return axios.get("/api/config").then(data => {
      updateSession({client_config: data.client_config, user: {permissions: []}, messages: []});
      return data;
    });
  },
  //  获取到菜单信息，切换菜单如果已有请求的信息则不请求
  requireSession() {
//     login.post("api/uuas/noToken/login",{
//       "account": "lamp",
//       "password": "lamp",
//       "tenantView": "0000",
//       "tenant": "MDAwMA==",
//       "code": "",
//       "grantType": "password",
//       "bindAccount": "",
//       "bindPassword": "",
//       "signAccount": "",
//       "signPassword": ""
//   },
//  {
//       headers:{
//         Authorization:"Basic bGFtcF93ZWI6bGFtcF93ZWJfc2VjcmV0",
//         tenant:"MDAwMA==",
//       }
//   }
//   ).then(res=>{
//     Auth.setApiKey(res.data.data.token);
//     localStorage.setItem("token",res.data.data.token);
// })
    // Auth.getUrlVal();
    // let url = document.location.toString();
    // // let url ="http://ai.test.adm.com/?0066ff&eyJ0eXAiOiJKc29uV2ViVG9rZW4iLCJhbGciOiJIUzI1NiJ9.eyJuYW1lIjoi56ef5oi3566h55CG5ZGYIiwidG9rZW5fdHlwZSI6InRva2VuIiwidXNlcmlkIjoiMiIsImFjY291bnQiOiJsYW1wIiwiaWF0IjoxNjM2MDA5MjMzLCJuYmYiOjE2MzYwMDkyMzMsImV4cCI6MTYzNjAzODAzM30.ZTDwiJLKZj29UHLMyaGl4k8vyCJ-QC_hYfeW2-uze0o"
    // // let param = (url.substring(url.indexOf('?') + 1, url.indexOf('#'))).split("&")
    // let param = (url.substring(url.indexOf('?') + 1, url.length)).split("&");
    // if (param.length > 1) {
    //   localStorage.setItem("token", param[1]);
    //   localStorage.setItem("tenant", param[2]);
    // }
    Auth.setApiKey(window.localStorage.getItem("token"));

    if (session.loaded) {
      return Promise.resolve(session);
    }

    return Auth.loadSession()
      .then(() => {
        if (Auth.isAuthenticated()) {
          return session;
        }
      })
      .catch(() => {
      });

  },
};
