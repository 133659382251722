import { get, includes } from "lodash";
import axiosLib from "axios";
import createAuthRefreshInterceptor from "axios-auth-refresh";
import { Auth } from "@/services/auth";
import qs from "query-string";
import { restoreSession } from "@/services/restoreSession";

// console.log("sssss",process.env.envConfig)

export const axios = axiosLib.create({
  paramsSerializer: params => qs.stringify(params),
  // xsrfCookieName: "csrf_token",
  // xsrfHeaderName: "X-CSRF-TOKEN",
  baseURL:"/api/bi"
});

axios.interceptors.response.use(response => response.data);

export const csrfRefreshInterceptor = createAuthRefreshInterceptor(
  axios,
  error => {
    const message = get(error, "response.data.message");
    if (error.isAxiosError && includes(message, "CSRF")) {
      return axios.get("/ping");
    } else {
      return Promise.reject(error);
    }
  },
  { statusCodes: [400] }
);

export const sessionRefreshInterceptor = createAuthRefreshInterceptor(
  axios,
  error => {
    const status = parseInt(get(error, "response.status"));
    const message = get(error, "response.data.message");
    // TODO: In axios@0.9.1 this check could be replaced with { skipAuthRefresh: true } flag. See axios-auth-refresh docs
    const requestUrl = get(error, "config.url");
    const refreshToken = localStorage.getItem('refreshToken');
    const code = get(error, 'response.data.code')
    if (code === 40009) {
      return axiosLib.post(window.location.origin+'/api/uuas/noToken/login', 
        {grantType: 'refresh_token', refreshToken},
        {
          headers: {
            'Authorization': 'Basic bGFtcF93ZWI6bGFtcF93ZWJfc2VjcmV0',
            'token': 'Bearer ' + window.localStorage.getItem("token"),
            'tenant': window.localStorage.getItem("tenant")
          }
        }
      ).then(response=> {
        const data = response.data
        console.log(data);
        console.log(data.code);
        if (data.code === 0) {
          window.localStorage.setItem("token", data.data.token)
          window.localStorage.setItem("expiration", data.data.expiration)
          window.localStorage.setItem("refreshToken", data.data.refreshToken)
          axios.request(error.config).then(res => {
            return res
          }).catch(err=> {
            console.log(err);
          })
        } else {
          console.log('过期');
          window.localStorage.setItem('TOKEN_PASS', 2)
        }
      }).catch(err => {
        console.log('过期');
        window.localStorage.setItem('TOKEN_PASS', 2)
      })
    } else {
      console.log(error, 'err');
      // if (error.isAxiosError && (status === 401 || includes(message, "Please login")) && requestUrl !== "api/session") {
      //   return restoreSession();
      // }
      return Promise.reject(error);
    }
  },
  {
    statusCodes: [401, 404],
    pauseInstanceWhileRefreshing: false, // According to docs, `false` is default value, but in fact it's not :-)
  }
);

// a标签的href、src不经过此请求
axios.interceptors.request.use(config => {
  // 全局设置token
  const apiKey = Auth.getApiKey();
  //  console.log("apiKey",apiKey);
  // console.log("apiKey",apiKey);
  if (localStorage.getItem("token")) {
    config.headers.Authorization = `Basic bGFtcF93ZWI6bGFtcF93ZWJfc2VjcmV0`;
    config.headers.token=`Bearer ${localStorage.getItem("token")}`;
    config.headers.tenant= `${localStorage.getItem("tenant")}`;
  }

  return config;
});
