import { isEmpty } from "lodash";
import moment from "moment";
import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";

import Table from "antd/lib/table"

import Link from "@/components/Link";
import LoadingOutlinedIcon from "@ant-design/icons/LoadingOutlined";

import { Dashboard } from "@/services/dashboard";
import { Query } from "@/services/query";

export function FavoriteList({ title, resource, itemUrl, emptyState }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    resource
      .favorites()
      .then(({ results }) => setItems(results))
      .finally(() => setLoading(false));
  }, [resource]);

  return (
    <>
      <div className="d-flex align-items-center m-b-20">
        <p className="flex-fill f-500 c-black m-0">{title}</p>
        {loading && <LoadingOutlinedIcon />}
      </div>
      {!isEmpty(items) && (
        <div role="list" className="list-group">
          {items.map(item => (
            <Link key={itemUrl(item)} role="listitem" className="list-group-item" href={itemUrl(item)}>
              <span className="btn-favorite m-r-5">
                <i className="fa fa-star" aria-hidden="true" />
              </span>
              {item.name}
              {item.is_draft && <span className="label label-default m-l-5">草稿</span>}
            </Link>
          ))}
        </div>
      )}
      {isEmpty(items) && !loading && emptyState}
    </>
  );
}

export function FavoriteList1({ title, resource, itemUrl, type=true, emptyState }) {
  const [items, setItems] = useState([]);
  const [loading, setLoading] = useState(true);

  const columns = [
    {
      title: '名称',
      dataIndex: 'name',
      key: 'name',
      render: (text, item) => (
        <Link key={itemUrl(item)} role="listitem" className="list-group-item" href={itemUrl(item)}>
          <span className="btn-favorite m-r-5">
            <i className="fa fa-star" aria-hidden="true" />
          </span>
          {item.name}
          {item.is_draft && <span className="label label-default m-l-5">草稿</span>}
        </Link>
      ),
    },
    {
      title: '创建人',
      dataIndex: 'name',
      key: 'name',
      render: (text, item) => (
        <span>{ item.user.name }</span>
      ),
    },
    {
      title: '创建时间',
      key: 'created_at',
      dataIndex: 'created_at',
      width: '90px'
    },
    {
      title: '最后执行时间',
      key: 'updated_at',
      dataIndex: 'updated_at',
      width: '120px'
    }
  ]
  if (!type) columns.pop()

  useEffect(() => {
    setLoading(true);
    resource
      .favorites()
      .then(({ results }) => {
        const obj = results.map(item=> {
          if (item.created_at) {
            item.created_at = moment(item.created_at).format("YYYY-MM-DD HH:mm:ss")
          }
          if (item.updated_at) {
            item.updated_at = moment(item.updated_at).format("YYYY-MM-DD HH:mm:ss")
          }
          return item
        })
        setItems(results)
      }).finally(() => setLoading(false));
  }, [resource]);

  return (
    <>
      <div className="d-flex align-items-center m-b-20">
        <p className="flex-fill f-500 c-black m-0">{title}</p>
        {loading && <LoadingOutlinedIcon />}
      </div>
      {/* {!isEmpty(items) && (
        <div role="list" className="list-group"> */}
          <Table
            columns={columns}
            dataSource={items}
            pagination={false}
            className="home-table"
            rowKey="id"
            scroll={{
              y: 200,
            }}
          />
          {/* {items.map(item => (
            <Link key={itemUrl(item)} role="listitem" className="list-group-item" href={itemUrl(item)}>
              <span className="btn-favorite m-r-5">
                <i className="fa fa-star" aria-hidden="true" />
              </span>
              {item.name}
              {item.is_draft && <span className="label label-default m-l-5">草稿</span>}
            </Link>
          ))}
        </div>
      )} */}
      {/* {isEmpty(items) && !loading && emptyState} */}
    </>
  );
}

FavoriteList.propTypes = {
  title: PropTypes.string.isRequired,
  resource: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  itemUrl: PropTypes.func.isRequired,
  emptyState: PropTypes.node,
};
FavoriteList.defaultProps = { emptyState: null };

FavoriteList1.propTypes = {
  title: PropTypes.string.isRequired,
  resource: PropTypes.func.isRequired, // eslint-disable-line react/forbid-prop-types
  itemUrl: PropTypes.func.isRequired,
  type: PropTypes.bool,
  emptyState: PropTypes.node,
};
FavoriteList1.defaultProps = { emptyState: null };

export function DashboardAndQueryFavoritesList() {
  return (
    <div className="tile" style={{flex: '1'}}>
      <div className="t-body tb-padding">
        <div className="row home-favorites-list">
          <div className="col-sm-6">
            <FavoriteList1
              title="关注的报表"
              resource={Dashboard}
              itemUrl={dashboard => dashboard.url}
              type={false}
              emptyState={
                <p>
                  <span className="btn-favorite m-r-5">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                  {/* <Link href="dashboards">您关注的报表将显示在这里</Link> */}
                  <Link>您关注的报表将显示在这里</Link>
                </p>
              }
            />
          </div>
          <div className="col-sm-6">
            <FavoriteList1
              title="关注的查询"
              resource={Query}
              itemUrl={query => `queries/${query.id}`}
              emptyState={
                <p>
                  <span className="btn-favorite m-r-5">
                    <i className="fa fa-star" aria-hidden="true" />
                  </span>
                  {/* <Link href="queries">您关注的查询将显示在这里</Link> */}
                  <Link>您关注的查询将显示在这里</Link>
                </p>
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
}
