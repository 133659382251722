import React, { useMemo } from "react";
import { first, includes } from "lodash";
import Menu from "antd/lib/menu";
import Link from "@/components/Link";
import PlainButton from "@/components/PlainButton";
import HelpTrigger from "@/components/HelpTrigger";
import CreateDashboardDialog from "@/components/dashboards/CreateDashboardDialog";
import { useCurrentRoute } from "@/components/ApplicationArea/Router";
import { Auth, currentUser } from "@/services/auth";
import settingsMenu from "@/services/settingsMenu";
import logoUrl from "@/assets/images/anpnm-ij32p.png";

import DesktopOutlinedIcon from "@ant-design/icons/DesktopOutlined";
import CodeOutlinedIcon from "@ant-design/icons/CodeOutlined";
import AlertOutlinedIcon from "@ant-design/icons/AlertOutlined";
import PlusOutlinedIcon from "@ant-design/icons/PlusOutlined";
import QuestionCircleOutlinedIcon from "@ant-design/icons/QuestionCircleOutlined";
import SettingOutlinedIcon from "@ant-design/icons/SettingOutlined";
import HomeOutlined from "@ant-design/icons/HomeOutlined";
// import VersionInfo from "./VersionInfo";

import "./DesktopNavbar.less";

function NavbarSection({ children, ...props }) {
  return (
    <Menu selectable={false} mode="vertical" theme="dark" {...props}>
      {children}
    </Menu>
  );
}

function useNavbarActiveState() {
  const currentRoute = useCurrentRoute();
  return useMemo(
    () => ({
      bi: includes(["Home"], currentRoute.id),
      dashboards: includes(
        [
          "Dashboards.List",
          "Dashboards.Favorites",
          "Dashboards.My",
          "Dashboards.ViewOrEdit",
          "Dashboards.LegacyViewOrEdit",
        ],
        currentRoute.id
      ),
      queries: includes(
        [
          "Queries.List",
          "Queries.Favorites",
          "Queries.Archived",
          "Queries.My",
          "Queries.View",
          "Queries.New",
          "Queries.Edit",
        ],
        currentRoute.id
      ),
      dataSources: includes(["DataSources.List"], currentRoute.id),
      alerts: includes(["Alerts.List", "Alerts.New", "Alerts.View", "Alerts.Edit"], currentRoute.id),
      status: includes([ "Admin.SystemStatus"], currentRoute.id),
    }),
    [currentRoute.id]
  );
}

export default function DesktopNavbar() {
  const firstSettingsTab = first(settingsMenu.getAvailableItems());
  const activeState = useNavbarActiveState();

  // const canCreateQuery = currentUser.hasPermission("create_query");
  // const canCreateDashboard = currentUser.hasPermission("create_dashboard");
  // const canCreateAlert = currentUser.hasPermission("list_alerts");

  return (
    <nav className="desktop-navbar">
      {/* <NavbarSection className="desktop-navbar-logo" >
        <div role="menuitem">
          <Link href="./">
            <img src={logoUrl} alt="BI-数据探索" />
          </Link>
        </div>
      </NavbarSection> */}
      {/* 路由生成文件 */}
      <NavbarSection>
        <Menu.Item key="Home" className={activeState.bi ? "navbar-active-item" : null}>
          <Link href={process.env.NODE_ENV === 'development' ? '/bi' : '/'}>
            <HomeOutlined aria-label="Home navigation button" />
            <span className="desktop-navbar-label">数据看板</span>
          </Link>
        </Menu.Item>
        {currentUser.hasPermission('list_dashboards') && (
          <Menu.Item key="dashboards" className={activeState.dashboards ? "navbar-active-item" : null}>
            <Link href="dashboards">
              <DesktopOutlinedIcon aria-label="Dashboard navigation button" />
              {/* <span className="iconfont icon-baobiao1"></span> */}
              <span className="desktop-navbar-label">报表</span>
            </Link>
          </Menu.Item>
        )}
        {currentUser.hasPermission("view_query") && (
          <Menu.Item key="queries" className={activeState.queries ? "navbar-active-item" : null}>
            <Link href="queries">
              <CodeOutlinedIcon aria-label="Queries navigation button" />
              {/* <span className="iconfont icon-chaxun"></span> */}
              <span className="desktop-navbar-label">查询</span>
            </Link>
          </Menu.Item>
        )}
        {currentUser.hasPermission("list_alerts") && (
          <Menu.Item key="alerts" className={activeState.alerts ? "navbar-active-item" : null}>
            <Link href="alerts">
              <AlertOutlinedIcon aria-label="Alerts navigation button" />
              {/* <span className="iconfont icon-tixing1"></span> */}
              <span className="desktop-navbar-label">提醒</span>
            </Link>
          </Menu.Item>
        )}
      </NavbarSection>

      {/* <NavbarSection className="desktop-navbar-spacer">
        {(canCreateQuery || canCreateDashboard || canCreateAlert) && (
          <Menu.SubMenu
            key="create"
            popupClassName="desktop-navbar-submenu"
            data-test="CreateButton"
            tabIndex={0}
            title={
              <React.Fragment>
                <PlusOutlinedIcon />
                <span className="desktop-navbar-label">新建</span>
              </React.Fragment>
            }>
            {canCreateQuery && (
              <Menu.Item key="new-query">
                <Link href="queries/new" data-test="CreateQueryMenuItem">
                  新建查询
                </Link>
              </Menu.Item>
            )}
            {canCreateDashboard && (
              <Menu.Item key="new-dashboard">
                <PlainButton data-test="CreateDashboardMenuItem" onClick={() => CreateDashboardDialog.showModal()}>
                  新建报表
                </PlainButton>
              </Menu.Item>
            )}
            {canCreateAlert && (
              <Menu.Item key="new-alert">
                <Link data-test="CreateAlertMenuItem" href="alerts/new">
                  新建提醒
                </Link>
              </Menu.Item>
            )}
          </Menu.SubMenu>
        )}
      </NavbarSection> */}

      <NavbarSection>
        {/* <Menu.Item key="help">
          <HelpTrigger showTooltip={false} type="HOME" tabIndex={0}>
            <QuestionCircleOutlinedIcon />
            <span className="desktop-navbar-label">帮助</span>
          </HelpTrigger>
        </Menu.Item> */}
         {currentUser.hasPermission("super_admin") && (
            <Menu.Item key="status" className={activeState.status ? "navbar-active-item" : null}>
              <Link href="admin/status">
                <HomeOutlined  />
                <span className="desktop-navbar-label">系统状态</span>
              </Link>
            </Menu.Item>
          )}
        {firstSettingsTab && (
          <Menu.Item key="settings" className={activeState.dataSources ? "navbar-active-item" : null}>
            <Link href={firstSettingsTab.path} data-test="SettingsLink">
              <SettingOutlinedIcon />
              {/* <span className="iconfont icon-shezhi1"></span> */}
              <span className="desktop-navbar-label">设置</span>
            </Link>
          </Menu.Item>
        )}
      </NavbarSection>

      {/* <NavbarSection className="desktop-navbar-profile-menu">
        <Menu.SubMenu
          key="profile"
          popupClassName="desktop-navbar-submenu"
          tabIndex={0}
          title={
            <span data-test="ProfileDropdown" className="desktop-navbar-profile-menu-title">
              <img className="profile__image_thumb" src={currentUser.profile_image_url} alt={currentUser.name} />
            </span>
          }>
          <Menu.Item key="profile">
            <Link href="users/me">个人设置</Link>
          </Menu.Item>
          {currentUser.hasPermission("super_admin") && (
            <Menu.Item key="status">
              <Link href="admin/status">系统状态</Link>
            </Menu.Item>
          )}
          <Menu.Divider />
          <Menu.Item key="logout">
            <PlainButton data-test="LogOutButton" onClick={() => Auth.logout()}>
              退出
            </PlainButton>
          </Menu.Item>
          <Menu.Divider />
          <Menu.Item key="version" role="presentation" disabled className="version-info">
            <VersionInfo />
          </Menu.Item>
            <Menu.Item key="version"  className="version-info">
            <PlainButton data-test="LogOutButton" onClick={() => Auth.goBack()}>
              返回平台
            </PlainButton>
          </Menu.Item>
        </Menu.SubMenu>
      </NavbarSection> */}
    </nav>
  );
}
