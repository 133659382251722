import { includes } from "lodash";
import React, { useEffect, useState } from "react";

import Alert from "antd/lib/alert";
import Card from 'antd/lib/card';
import Link from "@/components/Link";
import routeWithUserSession from "@/components/ApplicationArea/routeWithUserSession";
import EmptyState, { EmptyStateHelpMessage } from "@/components/empty-state/EmptyState";
import DynamicComponent from "@/components/DynamicComponent";
import BeaconConsent from "@/components/BeaconConsent";
import PlainButton from "@/components/PlainButton";

import { axios } from "@/services/axios";
import recordEvent from "@/services/recordEvent";
import { messages } from "@/services/auth";
import notification from "@/services/notification";
import routes from "@/services/routes";

import { DashboardAndQueryFavoritesList } from "./components/FavoritesList";
import { getDashboardsCount } from "@/api"

import "./Home.less";

// function DeprecatedEmbedFeatureAlert() {
//   return (
//     <Alert
//       className="m-b-15"
//       type="warning"
//       message={
//         <>
//           你已设置参数 <code>ALLOW_PARAMETERS_IN_EMBEDS</code>，但该特征暂时不可用。{" "}
//           <Link
//             href="https://discuss.redash.io/t/support-for-parameters-in-embedded-visualizations/3337"
//             target="_blank"
//             rel="noopener noreferrer">
//             Read more
//           </Link>
//           .
//         </>
//       }
//     />
//   );
// }

// function EmailNotVerifiedAlert() {
//   const verifyEmail = () => {
//     axios.post("verification_email/").then(data => {
//       notification.success(data.message);
//     });
//   };

//   return (
//     <Alert
//       className="m-b-15"
//       type="warning"
//       message={
//         <>
//           电子邮箱校验邮件已发送，请查收并点击邮件里的链接，已确认邮箱输入正确。{" "}
//           <PlainButton type="link" onClick={verifyEmail}>
//             重新发送邮件
//           </PlainButton>
//           .
//         </>
//       }
//     />
//   );
// }

// function BiCard(props) {
//   const { title , content } = props
//   console.log(content);
//   return (
//     <Card 
//       title={ title }
//       bordered={false}
//       headStyle={{ fontSize: '20px', fontWeight: '500', border: '0' }}
//       style={{ width: '100%', boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)' }}
//     >
//       <div className="card-body d-flex">
//         <div className="card-item">
//           { content.length > 0 && content.map((item, index)=> {
//             return (
//               <div style={{ marginLeft: '20%' }} key={index}>
//                 <span>{ item.label }</span>
//                 <p>{ item.num }</p>
//               </div>
//             )
//           }) }
//         </div>
//       </div>
//     </Card>
//   )
// }

export default function Home() {
  const [data, setData] = useState({
    alter: 0,
    dataSource: 0,
    search: 0,
    report: 0,
    queues: 0,
    workers: 0
  })

  useEffect(() => {
    recordEvent("view", "page", "personal_homepage");
    getDashboardsCount().then(res=> {
      console.log(res);
      const obj = {
        alter: res.alter_list.length,
        dataSource: res.data_source_list.length,
        search: res.search_list.count,
        report: res.report_list.count,
        queues: res.rq_queues,
        workers: res.rq_workers
      }
      setData(obj)
    })
  }, []);
  return (
    <div className="home-page">
      <div className="container">
        <div className="home-title">
          数据看板
        </div>
        <Card 
          title="数据统计" 
          bordered={false}
          headStyle={{ fontSize: '20px', fontWeight: '500', border: '0' }}
          style={{ width: '100%', boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)' }}
        >
          <div className="card-body d-flex">
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>报表总数</span>
                <p>{ data.report }</p>
              </div>
            </div>
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>查询总数</span>
                <p>{ data.search }</p>
              </div>
            </div>
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>提醒总数</span>
                <p>{ data.alter }</p>
              </div>
            </div>
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>数据源总数</span>
                <p>{ data.dataSource }</p>
              </div>
            </div>
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>正在执行任务</span>
                <p>{ data.workers }</p>
              </div>
            </div>
            <div className="card-item data-statis">
              <div style={{ marginLeft: '20%' }}>
                <span>正在排队任务</span>
                <p>{ data.queues }</p>
              </div>
            </div>
          </div>
        </Card>

        <Card 
          title="常用功能" 
          bordered={false}
          headStyle={{ fontSize: '20px', fontWeight: '500', border: '0' }}
          bodyStyle={{ padding: '0 16px 16px' }}
          style={{ width: '100%', boxShadow: '0 2px 1px -1px rgb(0 0 0 / 20%), 0 1px 1px 0 rgb(0 0 0 / 14%), 0 1px 3px 0 rgb(0 0 0 / 12%)', margin: '16px 0' }}
        >
          <div className="card-body d-flex">
            <div className="card-item data-icon">
              <Link href="dashboards">
                  <div className="d-flex flex-column align-items-center">
                    <div className="item-img green">
                      <span className="iconfont icon-baobiao1"></span>
                    </div>
                    <div className="item-title">报表</div>
                  </div>
              </Link>
            </div>
            <div className="card-item data-icon">
              <Link href="queries">
                <div className="d-flex flex-column align-items-center">
                  <div className="item-img green">
                    <span className="iconfont icon-chaxun"></span>
                  </div>
                  <div className="item-title">查询</div>
                </div>
              </Link>
            </div>
            <div className="card-item data-icon">
              <Link href="alerts">
                <div className="d-flex flex-column align-items-center">
                  <div className="item-img yellow">
                    <span className="iconfont icon-tixing1"></span>
                  </div>
                  <div className="item-title">提醒</div>
                </div>
              </Link>
            </div>
            <div className="card-item data-icon">
              <Link href="data_sources">
                <div className="d-flex flex-column align-items-center">
                  <div className="item-img blue">
                    <span className="iconfont icon-shujuyuan"></span>
                  </div>
                  <div className="item-title">数据源</div>
                </div>
              </Link>
            </div>
            <div className="card-item data-icon">
              <Link href="data_sources">
                <div className="d-flex flex-column align-items-center">
                  <div className="item-img yellow">
                    <span className="iconfont icon-shezhi1"></span>
                  </div>
                  <div className="item-title">设置</div>
                </div>
              </Link>
            </div>
            <div className="card-item data-icon">
              <Link href="admin/status">
                <div className="d-flex flex-column align-items-center">
                  <div className="item-img blue">
                    <span className="iconfont icon-xitongzhuangtai1"></span>
                  </div>
                  <div className="item-title">系统状态</div>
                </div>
              </Link>
            </div>
          </div>
        </Card>


        {/* {includes(messages, "using-deprecated-embed-feature") && <DeprecatedEmbedFeatureAlert />}
        {includes(messages, "email-not-verified") && <EmailNotVerifiedAlert />} */}
        {/* <DynamicComponent name="Home.EmptyState">
          <EmptyState
            header="欢迎使用BI-数据探索"
            description="连接任何数据源，轻松看见和分享数据。"
            illustration="dashboard"
            helpMessage={<EmptyStateHelpMessage helpTriggerType="GETTING_STARTED" />}
            showDashboardStep
            showInviteStep
            onboardingMode
          />
        </DynamicComponent> */}
        {/* <DynamicComponent name="HomeExtra" /> */}
        <DashboardAndQueryFavoritesList />
        {/* <BeaconConsent /> */}
      </div>
    </div>
  );
}
// bi文件首页
routes.register(
  "Home",
  routeWithUserSession({
    path: process.env.NODE_ENV === 'development' ? '/bi' : '/',
    title: "数据探索系统",
    render: pageProps => <Home {...pageProps} />,
  })
)

// routes.register(
//   "Home1",
//   routeWithUserSession({
//     path:"/bi",
//     title: "BI-数据探索",
//     render: pageProps => <Home {...pageProps} />,
//   })
// );
