const computedEleOuterHeight = el => {
  // Get the DOM Node if you pass in a string
  el = typeof el === 'string' ? document.querySelector(el) : el
  if (el == null) return 0
  var styles = window.getComputedStyle(el)
  var margin = parseFloat(styles['marginTop']) + parseFloat(styles['marginBottom'])

  return Math.ceil(el.offsetHeight + margin)
}

export const computedMainTableHeight = excludeEleIds => {
  var height = window.innerHeight - 50 - 48 - 32// windows.height - header - mainPadding - tableMainPadding -tableMargin
  if (excludeEleIds == null || excludeEleIds === undefined) return height
  if (Array.isArray(excludeEleIds)) {
      var _h = 0
      excludeEleIds.forEach(elementId => {
          _h += computedEleOuterHeight(elementId)
      })
      height -= _h
  } else if (excludeEleIds) {
      height = height - computedEleOuterHeight(excludeEleIds)
  }
  // console.logs(height);
  return height <= 200 ? 200 : height
}