import { extend } from "lodash";
import React, { useCallback } from "react";
import Modal from "antd/lib/modal";
import { Query } from "@/services/query";
import notification from "@/services/notification";
import useImmutableCallback from "@/lib/hooks/useImmutableCallback";

import navigateTo from "@/components/ApplicationArea/navigateTo";

function confirmArchive(type) {
  let titleType = '归档'
  if (type) {
    titleType = '删除'
  }
  return new Promise((resolve, reject) => {
    Modal.confirm({
      title: titleType+"查询",
      content: (
        <React.Fragment>
          <div className="m-b-5">{`确定要${titleType}查询？`}</div>
          <div>{`查询${titleType}后，所有和该查询以及视图关联的报表部件和提醒都将删除。`}</div>
        </React.Fragment>
      ),
      okText: titleType,
      cancelText: "取消",
      okType: "danger",
      onOk: () => {
        resolve();
      },
      onCancel: () => {
        reject();
      },
      maskClosable: true,
      autoFocusButton: null,
    });
  });
}

function doArchiveQuery(query) {
  let result = {
    id: query.id
  }
  if (query.is_delete) {
    result.is_delete = query.is_delete
  }
  return Query.delete(result)
    .then(() => {
      const ext = extend(query.clone(), { is_archived: true, schedule: null })
      navigateTo('queries')
      return ext
    })
    .catch(error => {
      notification.error(`查询未能${query.is_delete?'删除':'归档'}。`);
      return Promise.reject(error);
    });
}

export default function useArchiveQuery(query, onChange) {
  const handleChange = useImmutableCallback(onChange);

  return useCallback(() => {
    confirmArchive(query.is_delete)
      .then(() => doArchiveQuery(query))
      .then(handleChange);
  }, [query, handleChange]);
}
